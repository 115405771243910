import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/userTablets?companyId=${companyId}`).then((response) => response.data);
        } else {

            return axios.api.get("/userTablets").then((response) => response.data);
        }
    },
    getAllUserGroup: (companyId, userGroupId) => {
        return axios.api.get(`/userTablets?companyId=${companyId}&userGroupId=${userGroupId}`).then((response) => response.data);
    },

    getOne: (id) => {
        return axios.api.get(`/userTablets/${id}`).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/userTablets", body).then((response) => response.data);
    },

    update: (id, body) => {
        return axios.api.put(`/userTablets/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/userTablets/${id}`).then((response) => response.data);
    }
};
