import axios from "../instance";

export default {
    getAll: () => {
        return axios.api.get("/userGroups").then((response) => response.data);
    },

    getOne: (id) => {
        return axios.api.get(`/userGroups/${id}`).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/userGroups", body).then((response) => response.data);
    },

    update: (id, body) => {
        return axios.api.put(`/userGroups/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/userGroups/${id}`).then((response) => response.data);
    },
    addUserGroup: (body) => {
        return axios.api.post(`/userGroups/addUsers`, body).then((response) => response.data);
    },
    getAvalibleUsers: (companyId, userGroupId) => {
        return axios.api.get(`/userGroups/getAvalibleUsers?companyId=${companyId}&userGroupId=${userGroupId}`).then((response) => response.data);
    }
};
