import axios from "../instance";

export default {
    getAll: (userGroupId) => {
        return axios.api.get(`/userGroupItems?userGroupId=${userGroupId}`).then((response) => response.data);
    },

    getOne: (id) => {
        return axios.api.get(`/userGroupItems/${id}`).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/userGroupItems", body).then((response) => response.data);
    },

    update: (id, body) => {
        return axios.api.put(`/userGroupItems/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/userGroupItems/${id}`).then((response) => response.data);
    }
};
