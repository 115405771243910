import login from "./login";
import register from "./register";
import company from "./company";
import user from "./user";
import usergroup from "./usergroup";
import usergroupitem from "./usergroupitem";
import usertablet from "./usertablet";

export default {
    login,
    register,
    company,
    user,
    usergroup,
    usergroupitem,
    usertablet
};
