import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/users?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/users`).then((response) => response.data);
        }
    },
    searchQuery: (companyId, username) => {

        return axios.api.get(`/users?companyId=${companyId}&username=${username}`).then((response) => response.data);

    },
    search: (body) => {
        return axios.api.post("/users/search", body).then((response) => response.data);
    },
    getAllUserGroup: (companyId, userGroupId) => {
        return axios.api.get(`/users?companyId=${companyId}&userGroupId=${userGroupId}`).then((response) => response.data);
    },

    getOne: (id) => {
        return axios.api.get(`/users/${id}`).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/users/createAdmin", body).then((response) => response.data);
    },

    update: (id, body) => {
        return axios.api.put(`/users/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/users/${id}`).then((response) => response.data);
    },
    checkAuth: (userGroupId, masterMenuId) => {
        return axios.api.get(`/users/checkAuth?userGroupId=${userGroupId}&masterMenuId=${masterMenuId}`).then((response) => response.data);

    }
};
